@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

@font-face {
    font-weight: 300;
    font-family: 'Pretendard';
    src: url('/public/fonts/Pretendard-Light.woff') format('woff'),
    url('/public/fonts/Pretendard-Light.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-weight: normal;
    font-family: 'Pretendard';
    src: url('/public/fonts/Pretendard-Regular.woff') format('woff'),
    url('/public/fonts/Pretendard-Regular.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-weight: 500;
    font-family: 'Pretendard';
    src: url('/public/fonts/Pretendard-Medium.woff') format('woff'),
    url('/public/fonts/Pretendard-Medium.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-weight: Bold;
    font-family: 'Pretendard';
    src: url('/public/fonts/Pretendard-Bold.woff') format('woff'),
    url('/public/fonts/Pretendard-Bold.woff2') format('woff2');
    font-display: swap;
}

